'use strict'

module.exports = function () {
    $(document).ready(() => {
        const cache = {
            $body: $('body'),
            $accessSubscription: $('#accessSubscriptionBtn'),
            $modalAccessSubscription: $('#modalAccessSubscription'),
            $exclusiveProduct: $('#exclusiveProduct')
        };

        if (cache.$accessSubscription.length > 0) {
            cache.$accessSubscription.click();
            cache.$modalAccessSubscription.off();

            var buttonLogin = cache.$modalAccessSubscription.find('button[type="button"]');
            if(buttonLogin.length > 0) {
                buttonLogin.on('click', function() {
                    cache.$modalAccessSubscription.removeClass('show').addClass('hide');
                    $('.modal-backdrop').addClass('hide').removeClass('show');

                    const observer = new MutationObserver((mutations) => {
                        mutations.forEach((mutation) => {
                            if (mutation.attributeName === 'class') {
                                const target = mutation.target;

                                if (target.id === 'modalLogin' && target.classList.contains('show')) {
                                    $('#modalLogin').off()
                                }
                            }
                        });
                    });

                    observer.observe(document.getElementById('modalLogin'), { attributes: true, attributeFilter: ['class'] });
                });
            }
        }

        if (cache.$exclusiveProduct.length > 0) {
            window.location.href = cache.$exclusiveProduct.data('redirect');
        }
    });
}

